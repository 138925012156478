import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

Vue.mixin({
    created: function() {
        this.$root.pessoaId;
        this.$root.loading = false;
        this.$root.rootArray;
        this.$root.rootObject;
        // var myOption = this.$options.myOption
        // if (myOption) {
        //     console.log(111, myOption);
        // }
    },
    data: function() {
        return {
            pessoaId: '',
            loading: false,
            rootArray: [],
            rootObject: ''
        }
    }
});

const routes = [{
        path: '/',
        name: 'Login',
        component: () =>
            import ('../views/Login.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => sessionStorage.pessoaId ?
            import ('../views/Home.vue') : import ('../views/Login.vue')
    },
    {
        path: '/list',
        name: 'Listagem',
        component: () => sessionStorage.pessoaId ?
            import ('../views/List.vue') : import ('../views/Login.vue')
    },
    {
        path: '/EditDocument/:modeProfile',
        name: 'EditDocument',
        component: () => sessionStorage.pessoaId ?
            import ('../views/EditDocument.vue') : import ('../views/Login.vue')
    },
    {
        path: '/ViewDocument/:modeProfile',
        name: 'ViewDocument',
        props: true,
        component: () => sessionStorage.pessoaId ?
            import ('../views/ViewDocument.vue') : import ('../views/List.vue')
    },
    {
        path: '/AddDocument/:modeProfile',
        name: 'AddDocument',
        props: true,
        component: () => sessionStorage.pessoaId ?
            import ('../views/AddDocument.vue') : import ('../views/List.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;