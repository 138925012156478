<template>
  <div>
    <div id="app">
      <b-overlay :show="$root.loading" rounded="sm">
        <router-view/>
      </b-overlay>
      <Notifications />
      <div class="version">{{version}}</div>
    </div>
    <video-background
      v-if="!this.$root.pessoaId" 
      src="https://bpp.com.br/wp-content/themes/site-bpp/assets-2021/new-home/video/home.mp4"
      style="max-height: 100vw; height: 100vh;">
    </video-background>
    <div class="env-tag" v-if="homolog || development" v-bind:class="{ dev: development }"></div>
  </div>
</template>

<script>
import Notifications from "@/components/Notifications.vue";
import { version } from "../package.json";
const development = process.env.NODE_ENV === 'development';

export default {
  name: "App",
  components: {
    Notifications
  },
  created: function () {
  },
  data() {
    return {
      version,
      homolog: false,
      development
    }
  }
};
</script>

<style lang="scss">
.videobg-content {
  width: vw;
  height: vh;
  position: absolute;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: absolute;
  z-index: 999;
  width: 100vw;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none !important;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  a:hover {
    text-decoration: none !important;
  }
}
.version {
  position: fixed;
  z-index: 9999;
  bottom: 5px;
  left: 5px;
  color: #000;
  background: #FFF;
  padding-left: 7px;
  padding-right: 7px;
  font-size: 12px;
  border-radius: 3px;
  opacity: 0.3;
}

.version:hover {
  opacity: 1;
  cursor: pointer;
  color: #FFF;
  background: #6c757d;
}

.version::before {
  content: 'v';
}

.env-tag {
  background: #a23d46;
  background: linear-gradient(90deg, #a23d46 0%, #dc3545 30%, #dc3545 80%, #a23d46 100%);
  position: fixed;
  z-index: 9999;
  width: 200px;
  text-align: center;
  color: #FFF;
  font-size: 12px;
  height: 28px;
  padding-top: 4px;
  font-variant: petite-caps;
  transform: rotate(45deg);
  box-shadow: 3px 10px 11px rgb(0 0 0 / 40%);
  top: 28px;
  right: -56px;
  opacity: 0.5;
}

.env-tag:hover {
  opacity: 0.9;
  cursor: pointer;
}

.env-tag::after {
  content: 'Homologacao';
}

.env-tag.dev{
  background: #222f79;
  background: linear-gradient(90deg, #222f79 0%, #3748a5 30%, #3748a5 80%, #222f79 100%);
}

.env-tag.dev::after {
  content: 'Development';
}
</style>
