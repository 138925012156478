<template>
  <div id="notifications">
    <b-alert
      :show="dismissCountDown"
      dismissible
      :variant="variant"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p>{{ label }} ({{ dismissCountDown }})</p>
      <b-progress
        :variant="variant"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "Notifications",
  created: function () {

  },
  data() {
    return {
      variant: '',
      label: '',
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(data) {
      this.label = data.label;
      this.dismissSecs = data.time;
      this.variant = data.type;
      this.dismissCountDown = this.dismissSecs;
    }
  },
  mounted () {
    this.$root.$on('notify', this.showAlert);
  }
};
</script>

<style scoped>
#notifications {
  position: fixed;
  width: 35vw;
  height: 80px;
  left: 0px;
  bottom: 0px;
  z-index: 999;
  margin-left: 10px;;
}
</style>